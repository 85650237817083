import { ContactFormRequest } from '~/enums'

export const FORMATED_PHONE_LENGTH = 16

export const MAX_TOWN_NAME_LENGTH = 50
export const MAX_CLINIC_TITLE_LENGTH = 100

export const CONTACT_TYPES = [
  {
    label: 'Клиника',
    value: ContactFormRequest.ConnectClinic
  },
  {
    label: 'Страховая',
    value: ContactFormRequest.ConnectInsurance
  },
  {
    label: 'Сервис',
    value: ContactFormRequest.ConnectService
  }
]
