import type { ContactFormRequest } from '~/enums'

type OldSaveContactPayload = {
  requestType: ContactFormRequest
  mobilePhone: string
}

type SaveClinicContact = {
  contactType: ContactFormRequest.ConnectClinic
  mobilePhone: string
  town: string
  clinic: string
}

type SaveInsuranceContact = {
  contactType: ContactFormRequest.ConnectInsurance
  mobilePhone: string
}

type SaveServiceContact = {
  contactType: ContactFormRequest.ConnectService
  mobilePhone: string
}

// Удалить OldSaveContactPayload при удалении флага feature_use_new_contact_form
type SaveContactPayload = SaveServiceContact | SaveInsuranceContact | SaveClinicContact | OldSaveContactPayload

export type SaveContactErrorDto = {
  requestType?: string[]
  mobilePhone?: string[]
  detail?: string
}

export const saveContactApi = {
  saveContact(payload: SaveContactPayload) {
    return $apiFetch<SaveContactErrorDto>('/save_contact/', {
      body: payload,
      method: 'POST'
    })
  }
}
